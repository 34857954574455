<template>
  <section class="content">
    <table class="table table-hover" ref="tableafiliasi">
      <thead>
        <tr>
          <th>Mulai</th>
          <th>Berakhir</th>
          <th>Membership</th>
          <th>Aktif</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
                <b>Please correct the following error(s):</b>
                <ul>
                  <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                </ul>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </p>
              <div class="row">
                <div class="col-sm-6 form-group">
                  <label class="control-label">Periode</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      ref="daterange"
                      class="form-control"
                      style="border-right: 0"
                    />
                    <div class="input-group-append">
                      <div
                        class="input-group-text"
                        style="background-color: #fff"
                      >
                        <span><span class="fa fa-calendar"></span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 form-group">
                  <label class="control-label">Membertype</label>
                  <select
                    v-model="form.membership_type_id"
                    id="membership_type_id"
                    name="membership_type_id"
                    class="form-control"
                  >
                    <option
                      v-for="member in membership"
                      v-bind:key="member.id"
                      v-bind:value="member.id"
                    >
                      {{ member.title }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-6 form-group">
                  <label>
                    <input
                      id="active"
                      v-model="form.active"
                      type="checkbox"
                      name="active"
                      class="pr-3"
                    />
                    Active
                  </label>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button type="button" class="btn btn-secondary"
                data-dismiss="modal"> Close </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";

export default {
  name: "DataAfiliasi",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      membership: [],
      formTitle: "Tambah Afiliasi",
      form: {
        start_date: moment().startOf("month").format("YYYY-MM-DD"),
        end_date: moment().endOf("month").format("YYYY-MM-DD"),
        membership_type_id: "",
        active: false,
      },
      dt1: moment().startOf("month"),
      dt2: moment().endOf("month"),
    };
  },
  components: {
        vSelect,
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    //get data membership for dropdown select
    authFetch("/master/membertype")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.membership = js.data;
      });
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;
      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/master/affiliate";
      if (this.method == "PUT")
        urlSubmit = "/master/affiliate/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.errors = [];
        if (!js.success) {
          console.log(js.details);

          for (var key in js.details) {
            if (js.details.hasOwnProperty(key)) {
              this.errors.push(js.details[key]);
            }
          }

          return;
        }
        this.table.api().ajax.reload();
        $(e.formDialog).modal("hide");
      });

      ev.preventDefault();
    },
  },
  mounted() {
    const e = this.$refs;
    var self = this;
    new Daterangepicker(
      this.$refs.daterange,
      {
        startDate: !self.dt1 ? moment().startOf("month") : self.dt1,
        endDate: !self.dt2 ? moment().endOf("month") : self.dt2,
        locale: {
          format: 'DD/MM/YYYY'
        },
      },
      function (d1, d2) {
        self.form.start_date = d1.format("YYYY-MM-DD");
        self.form.end_date = d2.format("YYYY-MM-DD");
      }
    );
    this.table = createTable(e.tableafiliasi, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/master/affiliate",
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      columns: [
        { data: "start_date" },
        { data: "end_date" },
        { data: "membership_type_id", render: function (data, type, row, meta) { return row.title } },
        { data: "active" },
      ],
      filterBy: [ 0, 1, 2, 3 ],
      rowCallback: function (row, data) {
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          // self.form = {};
          new Daterangepicker(
            self.$refs.daterange,
            {
              start_date: moment().startOf("month").format("YYYY-MM-DD"),
              end_date: moment().endOf("month").format("YYYY-MM-DD"),
              locale: {
                format: 'DD/MM/YYYY'
              },
            },
            function (d1, d2) {
              self.form.start_date = d1.format("YYYY-MM-DD");
              self.form.end_date = d2.format("YYYY-MM-DD");
            }
          );
          self.form.membership_type_id = "";
          self.form.active = false;
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Afiliasi";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          new Daterangepicker(
            self.$refs.daterange,
            {
              startDate: moment(evt.data.start_date),
              endDate: moment(evt.data.end_date),
              locale: {
                format: 'DD/MM/YYYY'
              },
            },
            function (d1, d2) {
              self.form.start_date = d1.format("YYYY-MM-DD");
              self.form.end_date = d2.format("YYYY-MM-DD");
            }
          );
          self.form.start_date = evt.data.start_date;
          self.form.end_date = evt.data.end_date;
          self.form.active = evt.data.active == 0 ? false : true;
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Afiliasi";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          console.log(evt);
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/master/affiliate/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
      initComplete: function () {
        $('.loading-overlay').removeClass('show');
      },
    });
  },
};
</script>